import React from 'react';
import styles from './About.module.css';
import Header from './Header';
import Footer from './Footer';
import SEO from './SEO';


const About = ({siteName, links}) => {
    return (
        <div className="App">
        <SEO title={"Professor Pages - About"} description={"The origin story of Professor Pages"} type={"website"} />
      
        <Header siteName={siteName} links={links} />
        <div className={styles.about_outer}>
            <div className={styles.about_inner}>
                <div className={styles.about_title}><h1>About</h1></div>
                <h4>Professor Pages is a division of <a href="https://www.tinydesignlab.ca">Tiny Design Lab</a>, which are both headed by me, Shawn Kauenhofen.</h4>
                <div className={styles.about_text}>
                    <p>Professor Pages was born in 2016, when I officially took over running the academic website of my esteemed wife, Dr. Janeen Loehr (<a href="https://www.janeenloehr.com">janeenloehr.com</a>). Like many academics, she had taught herself enough CSS and html to build a simple website, but then struggled to keep it up to date as her workload increased and her website consistently fell to the bottom of her priority list. In 2016, I redesigned her website and took over updating it. Ever since then, she simply sends me an email every time she publishes a new paper, students graduate or new students join the lab, or her research makes the news. We celebrate 
                        (<svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="16" viewBox="0 0 24 24" width="16"><g><rect fill="none" height="24" width="24"/></g><g><g><polygon points="2,22 16,17 7,8"/><path d="M14.53,12.53l5.59-5.59c0.49-0.49,1.28-0.49,1.77,0l0.59,0.59l1.06-1.06l-0.59-0.59c-1.07-1.07-2.82-1.07-3.89,0 l-5.59,5.59L14.53,12.53z"/><path d="M10.06,6.88L9.47,7.47l1.06,1.06l0.59-0.59c1.07-1.07,1.07-2.82,0-3.89l-0.59-0.59L9.47,4.53l0.59,0.59 C10.54,5.6,10.54,6.4,10.06,6.88z"/><path d="M17.06,11.88l-1.59,1.59l1.06,1.06l1.59-1.59c0.49-0.49,1.28-0.49,1.77,0l1.61,1.61l1.06-1.06l-1.61-1.61 C19.87,10.81,18.13,10.81,17.06,11.88z"/><path d="M15.06,5.88l-3.59,3.59l1.06,1.06l3.59-3.59c1.07-1.07,1.07-2.82,0-3.89l-1.59-1.59l-1.06,1.06l1.59,1.59 C15.54,4.6,15.54,5.4,15.06,5.88z"/></g></g></svg>) 
                        and then I update her website. Over the years, we realized that other academics might benefit from the same service, and I built Professor Pages to make that possible.</p>
                    <p>Tiny Design Lab was born in 2012, with a similar backstory. Every so often, my wife would ask me if I could create some random item to support her research. The first item was a digital drawing of a dual-EEG system for a CFI grant application. Since then I have helped her create figures for papers (e.g., Figure 1 in this <a href="https://psyarxiv.com/fy4kn/">paper</a>), soldered together electronics and programmed software for experiment setups (e.g., the music boxes setup in this <a href="https://doi.org/10.1016/j.concog.2023.103521">paper</a>, and provided user interface design and engineering consulting on the <a href="https://github.com/LoehrLab/MMM_Duet_System">Music Memory Makers project</a>. I also built a prototype eye tracking system for another cognitive neuroscience lab. As my wife’s projects and interactions with other academics expanded over the years, she often found herself telling other people, “I know a guy who could do that [random design task] for you!” Hence Tiny Design Lab’s slogan, “You have a guy for that!” came to be.</p>
                    <p>Up until 2023, Professor Pages and Tiny Design Lab were back burner projects while I finished my degree in <a href="https://www.cs.usask.ca/students/undergraduate/undergraduate-programs/applied-computing/interactive-systems-design.php">Interactive System Design</a> as a part-time student and stay-at-home dad. (Interactive System Design is a BA&Sc degree geared toward user interface design, which combines computer science, visual/digital art, and cognitive psychology, and which complements my previous design experience as a civil/structural engineer). In 2023, after I finished my degree and the childcare disruptions caused by Covid-19 had settled down, I decided to focus my time on developing these skills into a businesses.</p>
                </div>
            </div>
        </div>
        <Footer siteName={siteName} />
        </div>
    );
}

export default About;
