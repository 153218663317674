import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import styles from './Header.module.css';


const Header = ({ siteName, links }) => {




  
    const [anchorEl, setAnchorEl] = useState(null);

   

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };


   
        return (
            <header className={styles['header_outer']}>
                <div className={styles['header_inner']}>
                <a href="/"><div className={styles['site-name']}> {siteName}</div></a>
                    <IconButton
                        aria-controls="mobile-menu"
                        aria-haspopup="true"
                        onClick={handleMenuClick}
                        color="inherit"
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu

                        className="mobile-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}

                    >
                        {links.map((link, index) => (
                            <MenuItem
                                key={index}
                                onClick={handleMenuClose}
                                className="MenuItem"
                            >
                                <a href={link.href} style={{
                                    textDecoration: 'none',
                                    color: '#0f0f0f'
                                }}>{link.name}</a>
                            </MenuItem>
                        ))}
                    </Menu>
                </div>
            </header>
        );
    
};

export default Header;
