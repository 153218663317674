import React from 'react';
import styles from './SplashContent.module.css';

const SplashContent = ({ title, subtitle, text }) => {
  return (
    <div className={styles.splash}>
      <div className={styles.splash_inner}>
      <div className={styles.title}><h1>{ title }</h1></div>
      {/* <div className="subtitle"><h2>{ subtitle }</h2></div> */}
      <div className={styles.text}><h3>{ text }</h3></div>
      </div>
    </div>
  );
}

export default SplashContent;
