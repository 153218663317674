import * as React from 'react';
import './index.css';
import About from './components/About';
import MainPage from './MainPage';
import {Helmet, HelmetProvider } from 'react-helmet-async';
import sitemap from './sitemap';
import ReactGA from 'react-ga';
import { ParallaxProvider } from 'react-scroll-parallax';
import {
  createBrowserRouter,
  RouterProvider,
  useRouteError
} from "react-router-dom";


ReactGA.initialize("G-BDKBE98CPP"); 

export default function App() {
  const siteName = "Professor Pages";
  const links = [
    { name: "Home", href: "/"},
    { name: "About", href: "/about" },
    { name: "Contact", href: "mailto:shawn@tinydesignlab.ca"},
  ];

  const router = createBrowserRouter([
    {
      path: "/",
      element: <MainPage siteName={siteName} links={links} />,
      errorElement: <ErrorBoundary />
    },
    {
      path: "/home",
      element: <MainPage siteName={siteName} links={links} />,
      errorElement: <ErrorBoundary />
    },
    {
      path: "/about",
      element: <About siteName={siteName} links={links} />,
      errorElement: <ErrorBoundary />
    },
    {
      path: "/sitemap.xml",
      element: sitemap,
      errorElement: <ErrorBoundary />
    }
  ]);
  
  function ErrorBoundary() {
    let error = useRouteError();
    console.error(error);
    // Uncaught ReferenceError: path is not defined
    return <h1>Dang! Something went wrong</h1>;
  }

  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <React.StrictMode>

    <HelmetProvider>
      <Helmet>
        <title>{siteName}</title>
        <meta name="description" content="Professor Pages offers professional and customized web design services tailored specifically for academics, researchers, and professors. Enhance your online presence with our expertly crafted websites that showcase your work, achievements, and publications. Contact us today for a visually appealing and functional website that represents your scholarly endeavors." />
        <meta name="keywords" content="professor pages, web design services, academics, researchers, professors, academic website, academic web site, professor website, researcher website, website development, online presence, customized websites, scholarly web design, academic web design, professional websites, showcase publications, online portfolio" />
      </Helmet>
    <ParallaxProvider>


      <RouterProvider router={router} />


    </ParallaxProvider>
    </HelmetProvider>
    </React.StrictMode>
  );
}
