import React from 'react';
import styles from './Footer.module.css'

const Footer = ({ siteName }) => {


  var today = new Date();
  let year = today.getYear()+1900;
  const footerText ="Copyright \u00A9" + year + " " + siteName +", a division of ";
  

  return (
    <footer className={styles.footer}>
      <div className={styles.footer_text}>{ footerText }{<a href='https://www.tinydesignlab.ca'>Tiny Design Lab</a>} - All rights reserved</div>
    </footer>
  );
}

export default Footer;
