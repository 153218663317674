import * as React from 'react';
import { useRef } from 'react';
import Stories from './components/Stories';
import styles from './components/Stories.module.css';
import conversation from './images/Speech.svg';
import ideation from './images/Thought.svg';
import creation from './images/Brush.svg';
import care from './images/Care.svg';
import creation_bg from './images/creating.webp';
import meeting_bg from './images/meeting.webp';
import ideation_bg from './images/ideation.webp';
import launch_bg from './images/launch.webp';
import { ParallaxProvider, ParallaxBanner } from 'react-scroll-parallax';


export default function Approach() {

  const app = useRef(null);
  const chapters = [
    {
      title: "Step 1: Conversation",
      subtitle: "",
      text: [
        "Our process starts with a personalized consultation to understand your research/work area and objectives. We explore your goals, budget, timeline, and preferred level of engagement to ensure a tailored approach.",
        "Following our discussion, we provide you with a detailed summary that captures the essence of our conversation. This summary serves as a starting point, and you are welcome to review and refine our understanding by adding, removing, commenting on, or adjusting items as necessary. This will provide us with a solid foundation on which to ideate."
      ],
      img: process.env.PUBLIC_URL + conversation,
      img_alt: "Ideation Icon",
      bg_img: process.env.PUBLIC_URL + meeting_bg
    },
    {
      title: "Step 2: Ideation",
      subtitle: "",
      text: [
        "Following our initial consultation and a mutual agreement on the scope of work, we take the first step towards establishing your distinctive online presence by securing your desired domain name, if you don't already have one.",
        "Next, we provide you with a selection of design examples, reflecting our consultation, that will serve as a source of inspiration. These examples play a crucial role in understanding your preferences and ensuring that the design aligns with your unique vision. We will also suggest a curated selection of colour palettes based on our conversation, to aid in refining the design.",
        "We aim to seek your feedback and opinions in a manner that suits your preferred involvement, working back and forth with as few or as many iterations as you need. Our goal  is to create a hassle-free experience that respects your valuable time."
      ],
      img: process.env.PUBLIC_URL + ideation,
      img_alt: "Ideation Icon",
      bg_img: process.env.PUBLIC_URL + ideation_bg
    },
    {
      title: "Step 3: Creation",
      subtitle: "",
      text: [
        "As we create and develop your page, we notify you when we require your specific images or text. In the interim, we use placeholder text and images that provide the overall feel for what we are looking for. As you watch your webpage develop, we encourage you to freely express your thoughts and provide feedback. We embrace constructive criticism as an integral part of our creative DNA. After all, this website is a reflection of you, and it is our utmost priority to ensure that it captures your essence."
      ],
      img: process.env.PUBLIC_URL + creation,
      img_alt: "Creation Icon",
      bg_img: process.env.PUBLIC_URL + creation_bg
    }
  ]

  return (

    <div ref={app} className="approach">


      <div className="approach_inner">
        <div className="approach_header">
          <h1>Why Professor Pages?</h1>
          <h2>Our Approach</h2>
          <p>Professor Pages answers the demand for a low stress approach to your online web presence. We build your website for you and then help you keep it up to date. With an annual maintenance package, shoot us an email any time and we will update your publications, student list, or whatever you need. Why do it yourself when you have a guy for that!</p>
        </div>
        <Stories storyArray={chapters} />

        <div className={styles.story}>
          <div className={styles.chapter} >
            <div className={styles.icon_container}>
              <img src={care} className={styles.image} alt="Care Icon" />
            </div>
            <div className={styles.chapter_inner}>
            <div className={styles.parallax_banner}>
              <ParallaxProvider>
                <ParallaxBanner
                  className={styles.banner}
                  strength={800}
                  layers={[{ image: `${launch_bg}`, speed: -30 }]}
                />
              </ParallaxProvider>
            </div>
              <div className={styles.chapter_textblock}>
                <h2>Step 4: Post-Launch Care</h2>
                <div className={styles.text}>
                <p>Your site is live! Where you go next is up to you. Generally, the post-launch care we provide after the initial website development falls into one of three categories,  in which we take on anywhere from all to none of the maintenance workload: </p>
                  <ol>
                  <li><span className='list_title'>Continued Partnership: </span>We take care of your website for you on an ongoing basis. When you need to add publications, add trainees or move them to the ‘graduated!’ category, or make other changes to your website content, simply notify us by email and we will make the necessary changes within 2-3 business days. We also take care of any other website maintenance issues that arise, such as library updates or new domain registration requirements. We provide this service for an annual fee. </li>
                  <li><span className='list_title'>Content Management System (CMS): </span>Many universities have an infrastructure in place that allows academics to add website content using pre-established templates. Similarly, we can make such templates for your personalized webpage using a personalized CMS. You would be responsible for updating the website, but you could do so without learning to code. It takes a little longer to develop a website with a personalized CMS, and this cost would be included in our website development estimate.  </li>
                  <li><span className='list_title'>Self-Managed: </span>We make a state-of-the-art website, pass all control and login information to you, exchange pleasantries (and some guidance), and part ways. Any future changes would be up to you or whomever you designate. There are no additional fees for this service beyond the initial development fee.</li>
                  </ol>
                  <span className='list_title'>Note: </span>Regardless of which post-launch care plan you choose, you will need to pay an annual fee for web hosting (this is a fee that all websites are charged for the infrastructure that serves the website to the world) and for your domain name (this is a fee charged by regulators to point to where your website can be found). These fees are incorporated into the Continued Partnership post-launch care plan; you pay us and no one else. For the CMS and Freedom Plan, we will guide you through the process of registering with the web host and domain registrar.
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>



    </div>

  );
}