import * as React from 'react';
import './index.css'
import gsap from 'gsap';
import { Flip } from 'gsap/Flip';
import Header from './components/Header';
import SplashContent from './components/SplashContent';
import Approach from './Approach';
import Footer from './components/Footer';
import SinglePaneCarousel from './components/SinglePaneCarousel';
import SEO from './components/SEO';
import ReactGA from 'react-ga';






gsap.registerPlugin(Flip);




export default function MainPage({ siteName, links }) {
  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  return (


    <div className="App">
     
      <SEO title={"Professor Pages - Home"} description={"Web design services tailored for academics"} type={"website"} />
      <Header siteName={siteName} links={links} />
      <SplashContent title={"Web design services tailored for academics"}
        subtitle={""}
        text={"Our mission is to empower researchers, professors, and academics by simplifying the process of establishing a strong web presence. We pride ourselves in providing a personalized service that lessens our clients' workload."}
      />

      <SinglePaneCarousel />
   

      <Approach />

      <Footer siteName={siteName} />
      </div>


  );
}