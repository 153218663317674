import React from 'react';
import styles from './Stories.module.css';
import { ParallaxProvider, ParallaxBanner } from 'react-scroll-parallax';

const Stories = ({ storyArray }) => {
  return (
    <div className={styles.story}>
      {storyArray.map((chapter, index) => (
        <div key={index} className={styles.chapter}>
          <div className={styles.icon_container}>
            <img className={styles.image} src={chapter.img} alt={chapter.img_alt} />
          </div>
          <div className={styles.chapter_inner}>
            <div className={styles.parallax_banner}>
              <ParallaxProvider>
                <ParallaxBanner
                  className={styles.banner}
                  strength={800}
                  layers={[{ image: `${chapter.bg_img}`, speed: -30 }]}
                />
              </ParallaxProvider>
            </div>
            <div className={styles.chapter_textblock}>
              <h2>{chapter.title}</h2>
              {chapter.subtitle !== "" && <h2>{chapter.subtitle}</h2>}
              {chapter.text.map((paragraph, paragraphIndex) => (
                <p key={paragraphIndex} className={styles.text}>{paragraph}</p>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Stories;
